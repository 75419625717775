<template>
   <div class="container-fluid">
  <div class="row">
     <div class="col-sm-12">
        <div class="card">
           <div class="card-header d-flex justify-content-between">
              <div class="header-title">
                 <h4 class="card-title">Recibos de vencimento</h4>
              </div>
           </div>
           <div class="card-body">
              <div class="table-responsive">
                  <div class="row justify-content-between">
                     <div class="col-sm-6 col-md-6">
                        <!-- <b-input v-model="filter" placeholder="Pesquisar"></b-input> -->
                           <select class="form-control form-control" v-model="currentYear">
                              <option selected disabled>Year</option>
                              <option value="2022">2022</option>
                              <option value="2021">2021</option>
                              <option value="2020">2020</option>
                           </select>
                        <br>
                     </div>
                     <div class="col-sm-6 col-md-6 py-2">
                        <!-- <div class="list-files d-flex">
                           <router-link class="btn bg-primary" :to="{name: 'app.receipts-add'}">
                              Adicionar
                           </router-link>
                        </div> -->
                        <div class="list-files d-flex">
                           <a href="#" class="btn bg-primary" @click="syncDocs">
                              Sincronizar
                           </a>
                        </div>
                     </div>
                  </div>
                 <b-table 
                    :items="orderedReceipts" 
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :filter="filter"
                     @filtered="onFiltered"
                 >
                     <template #cell(actions) = "{ item }">
                        <div class="d-flex align-items-center list-user-action">
                           <a 
                              class="btn btn-sm bg-primary mr-1" 
                              target="_blank" 
                              :href="baseUrl + item['Nome ficheiro'] + '.pdf'" 
                              data-toggle="tooltip" 
                              data-placement="top" 
                              data-original-title="Download" 
                           >
                              <i class="ri-download-line mr-0"></i>
                           </a>
                        </div>                     
                     </template>
                 </b-table>      
              </div>
              <div class="row justify-content-between mt-3">
                 <div id="user-list-page-info" class="col-md-6">
                    <span>A apresentar o intervalo {{startIndex + 1}}-{{endIndex > totalRows ? totalRows : endIndex}} de {{totalRows}} registos</span>
                 </div>
                 <div class="col-md-6">
                    <nav aria-label="Page navigation example">
                       <ul class="pagination justify-content-end mb-0">
                          <bPagination 
                               :total-rows="totalRows" 
                               v-model="currentPage" 
                               :per-page="perPage" 
                               @input ="updatePage(currentPage)"
                           >
                           </bPagination>
                       </ul>
                    </nav>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
export default {
   name:'UserList',
   data() {
       return {
         baseUrl: "https://wilddourocrm.painelgestao.pt/storage/",
         receipts: localStorage.getItem('receipts') && Boolean(localStorage.getItem('receipts')) ? JSON.parse(localStorage.getItem('receipts')) : [],
         fields: ['Ano','Mês', {actions: { label: 'Ações'}}],
         perPage: 5,
         currentPage : 1,
         startIndex : 0,
         endIndex : 5,
         totalRows: 1,
         filter: null,
         currentYear: 2022
       }
   },
   mounted () {
      localStorage.removeItem('receipts');
      if(!localStorage.getItem('receipts') || Boolean(!localStorage.getItem('receipts'))) {
         this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/salarios.csv');
      }
   },
   computed:
   {
      orderedReceipts()
      {
         let year = this.currentYear;
         var yearReceipts = this.receipts;

         // Get receipts for the selected year
         if(year)
         {
            yearReceipts = yearReceipts.filter(function( obj ) {
               return obj["Ano"] == year;
            });
         }

         // Order by month
         var result = yearReceipts.sort((a, b) => b["Mês"].localeCompare(a["Mês"]));

         result.forEach(function(receipt)
         {
            switch(receipt["Mês"])
            {
               case "01": { receipt["Mês"] = "Janeiro"; break;}
               case "02": { receipt["Mês"] = "Fevereiro"; break;}
               case "03": { receipt["Mês"] = "Março"; break;}
               case "04": { receipt["Mês"] = "Abril"; break;}
               case "05": { receipt["Mês"] = "Maio"; break;}
               case "06": { receipt["Mês"] = "Junho"; break;}
               case "07": { receipt["Mês"] = "Julho"; break;}
               case "08": { receipt["Mês"] = "Agosto"; break;}
               case "09": { receipt["Mês"] = "Setembro"; break;}
               case "10": { receipt["Mês"] = "Outubro"; break;}
               case "11": { receipt["Mês"] = "Novembro"; break;}
               case "12": { receipt["Mês"] = "Dezembro"; break;}
            }
         });

         return result;
      }
   },
   methods: {
      readTextFile(file) {
         this.receipts = [];
         let self = this;

         let rawFile = new XMLHttpRequest();
         rawFile.open("GET", file, false);
         rawFile.onreadystatechange = function ()
         {
            if(rawFile.readyState === 4)
            {
               if(rawFile.status === 200 || rawFile.status == 0)
               {
                  let csv = rawFile.responseText;
                  let lines = csv.split('\n');
                  
                  // Get structure of object
                  let headers = lines[0].split(";") 
                  
                  for(let line = 1; line < lines.length; line++){
                     let values = lines[line].split(";");
                     let employee = {};

                     for (let index = 0; index < values.length - 1; index++) {
                        let key = headers[index];
                        employee[key] = values[index];
                     }
                     employee.id = uuidv4();
                     self.receipts.push(employee);
                  }        
               }
            }
         }
         rawFile.send(null);
      },
      updatePage : function(activePage) {
         this.currentPage = activePage;
         this.startIndex = (this.currentPage * 5) - 5;
         this.endIndex = this.startIndex + 5;
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      deleteItem(id) {
         this.receipts.splice(this.receipts.findIndex(function(i){
            return i.id === id;
         }), 1);
         localStorage.setItem('receipts', JSON.stringify(this.receipts))
      },
      syncDocs()
      {
         setTimeout(function(){alert('Documentos sincronizados com sucesso.')}, 1000);
      }
   },
   watch: {
      receipts: function() {
         localStorage.setItem('receipts', JSON.stringify(this.receipts))
      },
      orderedReceipts: function()
      {
         this.totalRows = this.orderedReceipts.length;
      }
   },
   
}
</script>
<style scoped>
>>>.page-link:focus{
  box-shadow: none;
}
</style>